import React, { useRef, useEffect } from 'react'
import classNames from 'classnames'
import { Error, Label } from 'src/components/forms'
import InputMask from 'react-input-mask'

const DEFAULT = classNames(
    'border border-solid border-grey-500 rounded mb-2',
    'bg-white overflow-hidden flex items-stretch',
    'text-base font-normal',
);

const INPUT = classNames(
    'appearance-none w-full px-4 py-3 flex-1 outline-none',
    'text-base font-normal',
);

const ENABLED = 'bg-white text-dark placeholder-grey-800';
const DISABLED = 'cursor-default bg-grey-100 text-grey-400 placeholder-grey-400';
const BOLD = 'font-medium text-lg leading-none text-center';

export function Affix({ children }) {
    return (
        <div className="flex">
            {children}
        </div>
    );
}

export function InnerInput(props) {
    const {
        type,
        name,
        id,
        value,
        isRequired,
        isDisabled,
        isFocused,
        placeholder,
        conditionalClasses,
        onChange,
        onFocus,
        onPaste,
    } = props;
    const ref = useRef(null);

    useEffect(function focusInput() {
        if (isFocused) {
            ref.current.focus();
        }
    }, [ref, isFocused]);

    return (
        <input
            ref={ref}
            className={classNames(INPUT, conditionalClasses)}
            type={type}
            name={name}
            id={id}
            value={value}
            placeholder={placeholder}
            required={isRequired}
            disabled={isDisabled}
            onChange={onChange}
            onFocus={onFocus}
            onPaste={onPaste}
        />
    );
}

export function InnerDateInput(props) {
    const {
        type,
        name,
        id,
        value,
        isRequired,
        isDisabled,
        isFocused,
        placeholder,
        conditionalClasses,
        onChange,
        onFocus,
        onPaste,
    } = props;
    const ref = useRef(null);

    useEffect(function focusInput() {
        if (isFocused) {
            ref.current.focus();
        }
    }, [ref, isFocused]);

    return (
        <InputMask
            mask="99/99/9999"
            maskChar=""
            ref={ref}
            className={classNames(INPUT, conditionalClasses)}
            type={type}
            name={name}
            id={id}
            value={value}
            placeholder={placeholder}
            required={isRequired}
            disabled={isDisabled}
            onChange={onChange}
            onFocus={onFocus}
            onPaste={onPaste}
        />
    );
}

export function InputContainer(props) {
    const {
        id,
        name,
        isDisabled,
        isBold,
        label,
        error,
        className,
        renderInnerInput,
        isRequired,
        renderPrefix
    } = props;
    const conditionalClasses = {
        [ENABLED]: !isDisabled,
        [DISABLED]: isDisabled,
        [BOLD]: isBold,
    };

    return (
        <>
            {label && <Label required={isRequired} field={id}>{label}</Label>}
            <div className={classNames(DEFAULT, className, conditionalClasses)}>
                {renderPrefix}
                {renderInnerInput({ ...props, conditionalClasses })}
            </div>
            <Error>{error}</Error>
        </>
    );
}

export function DateInput(props) {
    return (
        <InputContainer
            {...props}
            renderInnerInput={(props) => (
                <InnerDateInput {...props} />
            )}
        />
    )
}

export default function Input(props) {
    return (
        <InputContainer
            {...props}
            renderInnerInput={(props) => (
                <InnerInput {...props} />
            )}
        />
    );
}

Input.defaultProps = InputContainer.defaultProps = {
    type: 'text',
    isRequired: false,
    isDisabled: false,
    isFocused: false,
    isBold: false,
    placeholder: undefined,
    error: undefined,
    label: undefined,
    renderPrefix: undefined,
    renderSuffix: undefined,
    className: undefined,
    onFocus: undefined,
    onPaste: undefined,
};
